import React, { useEffect } from "react";

const HomePage: React.FC = () => {
  useEffect(() => {
    if (typeof location !== 'undefined') location.replace('/courses/ux-design');
  }, []);

  return <div />;
};

export default HomePage;
